import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const ProjectsPath = ({ data, location }) => {
  const siteTitle = `Now`;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title="Now"
        description={"I'm living in NYC and building at Imprint."}
      />
      <h1 className="main-heading">Now</h1>
      <p style={{ marginTop: 'calc(var(--spacing-8) / 2)' }}>
        I'm living in NYC and building at{' '}
        <a href="http://imprint.co">Imprint</a>.
      </p>
    </Layout>
  );
};

export default ProjectsPath;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
